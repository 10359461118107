import React from "react"
import { Link, graphql } from "gatsby"
import { MDXRenderer } from "gatsby-plugin-mdx"
import SEO from "../components/seo"

import Layout from "../components/layout"
import BlogStyles from "../components/styles/BlogStyles"
import PeteDoubleThumbs from "../images/pete/pete-double-thumbs.png"

const BlogPost = ({ data, scope, pageContext }) => {
  if (!data) {
    return null
  }
  const post = data.mdx
  const { prevPost, nextPost } = pageContext
  const {
    frontmatter: { title, author, date, tags },
    body,
  } = post

  return (
    <Layout>
      <SEO title={`PayPerks Blog | ${title}`} />
      <BlogStyles className="blog-single-post">
        <div className="section max-640 content-wrap mb-5">
          <h1 dangerouslySetInnerHTML={{ __html: title }} />
          <div className="post-meta">
            <span className="meta feed-meta author-thumbnail">
              <img src={PeteDoubleThumbs} alt="" />
            </span>
            <div className="author-and-date">
              <span className="meta feed-meta author">{author}</span>
              <span className="meta feed-meta post-date">on {date}</span>
            </div>
          </div>
          <div className="post-tags">
            {tags.map(tag => {
              return (
                <span className="post-tag" key={tag}>
                  {tag}
                </span>
              )
            })}
          </div>
          <hr className="hairline green1 mt-3 mb-3" />
          <MDXRenderer>{body}</MDXRenderer>
        </div>
        <hr className="hairline dotted mb-3 mt-3" />
        {/* Single Post Nav */}
        <div className="post-nav-block">
          {prevPost && (
            <div className="prev-block">
              <span className="link-kicker prev">&larr; previous</span>
              <Link
                to={prevPost.node.fields.slug}
                className="post-nav-link post-prev"
              >
                <h4
                  dangerouslySetInnerHTML={{
                    __html: prevPost.node.frontmatter.title,
                  }}
                />
              </Link>
            </div>
          )}
          {nextPost && (
            <div className="next-block">
              <span className="link-kicker next">next &rarr;</span>
              <Link
                to={nextPost.node.fields.slug}
                className="post-nav-link post-next"
              >
                <h4
                  dangerouslySetInnerHTML={{
                    __html: nextPost.node.frontmatter.title,
                  }}
                />
              </Link>
            </div>
          )}
        </div>
      </BlogStyles>
    </Layout>
  )
}

export default BlogPost

export const query = graphql`
  query($slug: String!) {
    mdx(fields: { slug: { eq: $slug } }) {
      body
      frontmatter {
        title
        tags
        author
        date(formatString: "LL")
      }
    }
  }
`
