import styled from "styled-components"

const BlogStyles = styled.div`
  .post-feed-item {
    margin: 0 auto ${props => props.theme.defaultSectionSpacing};
  }

  .post-tags {
    margin-bottom: ${props => props.theme.defaultSpacing};
  }

  .post-tag {
    display: inline-block;
    padding: 0 0.8rem;
    line-height: 1.8;
    background: ${props => props.theme.grey0};
    color: ${props => props.theme.grey1};
    text-transform: lowercase;
    font-size: 1.2rem;
    border-radius: 5px;
    margin: 0 1rem 0 0;
  }

  .post-meta {
    margin-bottom: ${props => props.theme.defaultSpacing};
    display: flex;
    align-items: center;
    font-size: 1.5rem;
    line-height: 1.5;

    .author-thumbnail {
      flex: 0 0 48px;
      width: 48px;
      height: 48px;
      border-radius: 50%;
      overflow: hidden;
      display: flex;
      align-items: center;
      background-color: ${props => props.theme.ltGreen0};
      margin-right: 1rem;

      img {
        width: 100%;
        height: auto;
      }
    }

    .author-and-date {
      flex: 1 0 auto;

      .author {
        display: block;
        font-weight: bold;
        color: ${props => props.theme.grey2};
      }

      .post-date {
        display: block;
        font-size: 1.2rem;
        color: ${props => props.theme.grey1};
      }
    }
  }

  .featured-image-frame {
    margin-bottom: ${props => props.theme.defaultSpacing};
  }

  // Prev / Next
  .post-nav-block {
    & > * {
      padding: ${props => props.theme.defaultSpacing};
    }

    .next-block {
      text-align: right;
    }

    .prev-block + .next-block {
      border-top: 1px dotted ${props => props.theme.ltGreen0};
    }

    .prev-block {
      text-align: left;
    }

    .post-nav-link {
      color: ${props => props.theme.grey3};
    }

    .link-kicker {
      display: block;
      color: ${props => props.theme.ltBrown};
      font-size: ${props => props.theme.fontSize.xs};
      font-weight: bold;
      letter-spacing: 1px;
      text-transform: uppercase;
      margin: 0 0 ${props => props.theme.defaultSpacing};

      &.next {
        text-align: right;
      }
    }
  }

  // sensible defaults for post body copy
  ul {
    padding-left: 2rem;
    line-height: ${props => props.theme.defaultBodyLineHeight};
    margin-bottom: ${props => props.theme.defaultSpacing};
    list-style: disc outside;

    li {
      margin-bottom: 0.5em;
    }

    li {
      &:last-of-type,
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .post-featured-image-frame,
  .post-image-frame {
    margin-bottom: ${props => props.theme.defaultSectionSpacingMobile};
    overflow: hidden;
  }

  .caption-text {
    display: block;
    font-size: ${props => props.theme.fontSize.s};
    color: ${props => props.theme.grey1};
    margin-top: 1rem;
  }

  @media (min-width: ${props => props.theme.breakpoints.m}px) {
    .post-nav-block {
      display: flex;
      justify-content: flex-end;

      & > * {
        width: 50%;
      }

      .next-block {
        padding-left: ${props => props.theme.defaultSectionSpacingMobile};
      }

      .prev-block + .next-block {
        border: none;
        border-left: 1px dotted ${props => props.theme.ltGreen0};
      }

      .prev-block {
        padding-right: ${props => props.theme.defaultSectionSpacingMobile};
        &:only-child {
          margin-right: 50%;
        }
      }
    }
  }
`

export default BlogStyles
