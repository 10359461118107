import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/worker/code/src/templates/blog-post.js";
import { Link } from "gatsby";
import { BLOG_HOME_URL } from "../constants/pageUrls";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <p>{`Today I had the privilege of sitting on a panel discussion entitled the Regulatory Effects on Payroll Card Programs with Cathy Beyda (the authority on Payroll Card legislation and an attorney at Paul Hastings) and Dawn Steele (Sr. Corporate Counsel for ADP). Honored to be in such great company.`}</p>
    <p>{`Our presentation covered three main topics:`}</p>
    <ul>
      <li parentName="ul">{`Benefits and Features of Payroll Cards`}</li>
      <li parentName="ul">{`Laws relating to Payroll Cards (The Fair Labor Standards Act (FLSA); The Electronic Funds Transfer Act (EFTA) and Regulation E FDIC Act and General Counsel No. 8)`}</li>
      <li parentName="ul">{`How to avoid legal pitfalls`}</li>
    </ul>
    <p>{`It strikes me at ironic that the focus of a talk on the Regulatory Effects on Payroll Card Programs is on how to stay in compliance as opposed to how to use regulation to mandate employers offer their unbanked employees a Payroll Card option.`}</p>
    <p>{`At the risk of being redundant, let me say once again that Payroll Cards provide a positive social impact. For the un and underbanked consumer, they offer the lowest cost banking solution on the market. They serve as the stepping stone between an all cash lifestyle and formal banking relationships. And they have the promise of providing lenders with better information on which to base loan decisions. States should be helping employers distribute these cards not hindering them through legislation.`}</p>
    <p>{`Next year I hope to sit on a similar panel. Same title but focused on using government regulations to aid in the proliferation of Payroll Cards.`}</p>
    <p>{`One more day here from the Prepaid Expo and then back to the NYC. Will continue to live tweet from `}<a parentName="p" {...{
        "href": "https://twitter.com/payperksb2b"
      }}>{`@payperksb2b`}</a>{` tomorrow! You can also read my report from `}<Link to={`${BLOG_HOME_URL}/report-day-1-at-the-prepaid-expo`} mdxType="Link">{`yesterday`}</Link>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      